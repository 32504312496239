import React, { useEffect } from 'react';

const RedirectPage = ({redirectTo}) => {
    const redirectDelay = 2000; // 遷移までの遅延時間（ミリ秒）

    const handleRedirect = () => {
        window.location.href = redirectTo; // 遷移処理
    };

    useEffect(() => {
        const timer = setTimeout(handleRedirect, redirectDelay); // 関数を呼び出す

        return () => clearTimeout(timer); // クリーンアップ
    }, [redirectDelay]); // redirectToは固定なので依存配列に含めない

    return (
        <div>
            <p>トップページに遷移します...</p>
        </div>
    );
};

export default RedirectPage;