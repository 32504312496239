import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import ANGEIMG from '../Ange/Card/X/X.PNG';
import { S3_IMAGE_URL, IMAGE_RESIZE } from "../HoloCard/Common/Constant";

const imagesAnge = {
    url: ANGEIMG,
    title1: 'アンジュ・ユナイト',
    title2: 'カード検索 デッキ作成',
    title3: '一人回しツール',
    width: '250px',
  };
  
const imagesHoloca = {
    url: S3_IMAGE_URL + IMAGE_RESIZE + "/X/X.png",
    title1: 'ホロカOCG',
    title2: '一人回しツール',
    width: '250px',
  };


const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  height: '250px', 
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center 40%',
});

const Image = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: 'absolute',
  bottom: -2,
  left: 'calc(50% - 9px)',
  transition: theme.transitions.create('opacity'),
}));

const AngeLink = () => {
  window.location.href = 'angeToolIndex.html';
};
  
const HoloLink = () => {
  window.location.href = 'holoToolIndex.html';
};

export default function IndexPage() {
  return (
    <Stack
      direction="column" // 縦に並べる
      justifyContent="center" // 垂直方向に中央揃え
      alignItems="center" // 水平方向に中央揃え
      sx={{
        backgroundColor: 'background.default',
        mt:5,
      }}
      spacing={3} // 要素間のスペース
    >
      <ImageButton
          focusRipple
          key={imagesAnge.title}
          style={{
            width: imagesAnge.width,
            height: imagesAnge.height,
          }}
          onClick={AngeLink}
        >
          <ImageSrc style={{ backgroundImage: `url(${imagesAnge.url})` }} />
          <ImageBackdrop className="MuiImageBackdrop-root" />
          <Image>
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              sx={(theme) => ({
                position: 'relative',
                p: 4,
                pt: 2,
                pb: `calc(${theme.spacing(1)} + 6px)`,
              })}
            >
              {imagesAnge.title1}
              <div/>
              {imagesAnge.title2}
              <div/>
              {imagesAnge.title3}
              <ImageMarked className="MuiImageMarked-root" />
            </Typography>
          </Image>
        </ImageButton>
        
        <ImageButton
          focusRipple
          key={imagesHoloca.title}
          style={{
            width: imagesHoloca.width,
            height: imagesHoloca.height,
          }}
          onClick={HoloLink}
        >
          <ImageSrc style={{ backgroundImage: `url(${imagesHoloca.url})` }} />
          <ImageBackdrop className="MuiImageBackdrop-root" />
          <Image>
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              sx={(theme) => ({
                position: 'relative',
                p: 4,
                pt: 2,
                pb: `calc(${theme.spacing(1)} + 6px)`,
              })}
            >
              {imagesHoloca.title1}
              <div/>
              {imagesHoloca.title2}
              <ImageMarked className="MuiImageMarked-root" />
            </Typography>
          </Image>
        </ImageButton>
        
        
        <h5>plusdrive haso</h5>
    </Stack>
  );
}