import { createRoot } from "react-dom/client";
import IndexPage from "./IndexPage/IndexPage";
import RedirectPage from "./IndexPage/RedirectPage";
import NotFound from "./IndexPage/NotFound";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import React, { useEffect } from 'react';

const forms = createRoot(document.getElementById("forms"));

const handleRedirect = (redirectTo) => {
        window.location.href = redirectTo; 
};

forms.render(
  <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/holoToolIndex.html/*" element={<RedirectPage redirectTo="/holoToolIndex.html" />} />
        <Route path="/angeToolIndex.html/*" element={<RedirectPage redirectTo="/angeToolIndex.html" />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  </>
);
